"use client";

import "./globals.css";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import { usePathname } from "next/navigation";

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const pathname = usePathname();
  const shouldHideUI = pathname === "/prize-payments-plpp1";
  return (
    <html lang="en">
      <head>
        <meta
          name="robots"
          content="max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta
          name="title"
          content="Payment Labs | Simplified Global Payments"
        />
        <meta
          name="description"
          content="Payment Labs effortlessly managing payments with the ability to transact in 140+ currencies across 180 countries, with minimal returns of less than 1%."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.paymentlabs.io" />
        <meta
          property="og:title"
          content="Payment Labs | Simplified Global Payments"
        />
        <meta
          property="og:description"
          content="Payment Labs effortlessly managing payments with the ability to transact in 140+ currencies across 180 countries, with minimal returns of less than 1%."
        />
        <meta
          property="og:image"
          content="https://cdn.paymentlabs.io/branding/opengraph_primary.png"
        />

        <meta name="twitter:site" content="@paymentlabs_io" />
        <meta name="twitter:creator" content="@paymentlabs_io" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.paymentlabs.io" />
        <meta
          property="twitter:title"
          content="Payment Labs | Simplified Global Payments"
        />
        <meta
          property="twitter:description"
          content="Payment Labs effortlessly managing payments with the ability to transact in 140+ currencies across 180 countries, with minimal returns of less than 1%."
        />
        <meta
          property="twitter:image"
          content="https://cdn.paymentlabs.io/branding/opengraph_primary.png"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#226CFF" />
        <meta
          name="msapplication-TileImage"
          content="/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#226CFF" />
        <title>Payment Labs | Simplified Global Payments</title>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W4WK398J');
        `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('lAxoEaK1p0w7OYGd');
        `,
          }}
        ></script>
        <script
          type="text/javascript"
          src="https://tag.clearbitscripts.com/v1/pk_78ff8b84edf97bc10259ff7f04524fb8/tags.js"
        ></script>
         <script
          id="vtag-ai-js"
          type="text/javascript"
          src="https://r2.leadsy.ai/tag.js"
          data-pid="mVg67V7YQ977hfNP"
          data-version="062024"
          async
        ></script>
      </head>
      <body>
        <Navigation hideLogin={shouldHideUI} hideRequestDemo={shouldHideUI} />
        {children}
        <Footer />
      </body>
    </html>
  );
}
