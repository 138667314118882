type ButtonProps = {
  type?: "submit" | "reset" | "button" | undefined;
  text: string;
  variant: "primary" | "secondary";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export default function Button({ text, variant, className, type, disabled, onClick = () => {} }: ButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`h-full justify-center px-8 py-2.5 rounded-lg ${
        disabled
          ? "text-white bg-[#707A8F]"
          : variant === "primary"
          ? "text-white bg-blue-600 hover:bg-brand-primary-2"
          : "text-blue-600 border-2 border-blue-600 border-solid hover:border-brand-primary-2 hover:text-brand-primary-2"
      } ${className}`}
    >
      {text}
    </button>
  );
} 
