import { useState, useEffect } from "react";
import Button from "./Button";

type NavItemProps = {
  text: string;
  href: string;
};

const NavItem: React.FC<NavItemProps> = ({ text, href }) => (
  <a className="font-semibold leading-7 text-gray-900" href={href}>
    {text}
  </a>
);

export default function Navigation({
  hideLogin,
  hideRequestDemo,
}: {
  hideLogin: boolean;
  hideRequestDemo: boolean;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isMenuOpen]);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", closeMenu);

    return () => {
      window.removeEventListener("resize", closeMenu);
    };
  }, [closeMenu]);

  return (
    <header className={isMenuOpen ? "h-screen bg-white" : ""}>
      <div className="flex justify-between items-center px-16 py-2.5 w-full text-base leading-6 bg-white border-b border-indigo-50 border-solid max-md:px-5 max-md:max-w-full">
        <a href="/">
          <img
            loading="lazy"
            src={`/paymentlabs/nav-logo.svg`}
            alt="Company Logo"
            className="shrink-0 max-w-full aspect-[6.25] w-[248px]"
          />
        </a>
        {isMenuOpen ? (
          <button
            type="button"
            className="md:hidden h-6 w-6"
            onClick={toggleMenu}
          >
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        ) : (
          <button
            type="button"
            className="md:hidden text-gray-700"
            onClick={toggleMenu}
          >
            <svg
              width="21"
              height="15"
              viewBox="0 0 21 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="1.5"
                x2="21"
                y2="1.5"
                stroke="#222C49"
                strokeWidth="3"
              />
              <line
                y1="7.5"
                x2="21"
                y2="7.5"
                stroke="#222C49"
                strokeWidth="3"
              />
              <line
                y1="13.5"
                x2="21"
                y2="13.5"
                stroke="#222C49"
                strokeWidth="3"
              />
            </svg>
          </button>
        )}

        <nav className="hidden md:block flex-col md:flex-row flex gap-5 justify-center py-3 my-auto text-gray-700 whitespace-nowrap md:flex">
          <NavItem text="Solutions" href="/solution" />
          <NavItem text="Pricing" href="/pricing" />
          <NavItem text="Company" href="/about" />
          <NavItem text="Blog" href="/blog" />
        </nav>
        <div className="hidden md:flex flex gap-4 font-bold">
          {!hideRequestDemo && (
            <a href="/request-demo">
              <Button text="Request a Demo" variant="primary" />
            </a>
          )}
          {!hideLogin && (
            <a href="https://app.paymentlabs.io/">
              <Button text="Sign In" variant="secondary" />
            </a>
          )}
          {hideLogin && hideRequestDemo && (
            // Width of the two buttons to avoid shifting of the nav center links
            <div className="min-w-[324px]" />
          )}
        </div>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden flex-col h-100 flex gap-5 justify-center my-auto text-gray-700 whitespace-nowrap md:flex p-8">
          <NavItem text="Solutions" href="/solution" />
          <NavItem text="Pricing" href="/pricing" />
          <NavItem text="Company" href="/about" />
          <NavItem text="Blog" href="/blog" />
        </nav>
      )}
    </header>
  );
}
