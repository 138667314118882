import axios from "axios";
import React, { FormEvent, useState } from "react";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<"success" | "error" | null>(null);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await axios.post("/api/newsletter-subscription", {
        email,
        currentPageUrl: window.location.href,
      });

      if (response.data.success) {
        setSubmitStatus("success");
        setEmail("");
      } else {
        setSubmitStatus("error");
      }
    } catch (error) {
      console.error("Submission error:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="flex flex-col justify-center items-center p-20 w-full bg-brand-primary-2 max-md:px-5 max-md:max-w-full">
      <div className="pb-9 mt-12 w-full max-w-[1170px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow text-base text-white max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                src={`/paymentlabs/white-logo.svg`}
                alt="Payment Labs logo"
                className="max-w-full aspect-[6.25] w-[309px]"
              />
              <p className="mt-4 max-md:max-w-full">
                How Payments Should Be - Fast, Global, And Always, Low Rates
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch text-base leading-6 max-md:mt-10 max-md:max-w-full">
              <p className="text-white max-md:max-w-full">
                Subscribe to our newsletter and get our latest updates
              </p>
              <form
                className="flex gap-4 flex flex-col md:flex-row gap-4"
                onSubmit={handleSubmit}
              >
                <div className="flex gap-4 px-3 py-2.5 mt-4 text-white whitespace-nowrap rounded-lg bg-indigo-50 bg-opacity-20 max-md:flex-wrap min-w-[300px]">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4.4707H20C21.1 4.4707 22 5.3707 22 6.4707V18.4707C22 19.5707 21.1 20.4707 20 20.4707H4C2.9 20.4707 2 19.5707 2 18.4707V6.4707C2 5.3707 2.9 4.4707 4 4.4707Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 6.4707L12 13.4707L2 6.4707"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <input
                    type="email"
                    placeholder="E-mail"
                    aria-label="Enter your email"
                    className="my-auto max-md:max-w-full bg-transparent border-none"
                  />
                </div>

                <button
                  className="px-6 py-2.5 mt-4 text-white whitespace-nowrap rounded-lg bg-indigo-50 bg-opacity-20 hover:bg-opacity-10"
                  type="submit"
                >
                  Submit
                </button>
              </form>
              {submitStatus === "success" && (
                <p className="mt-2 text-green-500">
                  Email submitted successfully!
                </p>
              )}
              {submitStatus === "error" && (
                <p className="mt-2 text-red-500">
                  An error occurred. Please try again.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="shrink-0 mt-10 max-w-full h-px bg-gray-400 border border-gray-400 border-solid w-[1170px]" />
      <div className="mt-10 w-full max-w-[1170px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col max-md:mt-10">
              <a
                href="mailto:hello@paymentlabs.io"
                className="text-base leading-6 text-white"
              >
                hello@paymentlabs.io
              </a>
              <div className="flex gap-5 pr-8 mt-4 max-md:pr-5">
                <a
                  target="_blank"
                  href="https://twitter.com/paymentlabs_io"
                  aria-label="Twitter"
                >
                  <svg
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5536_12869)">
                      <mask
                        id="mask0_5536_12869"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="32"
                        height="33"
                      >
                        <path
                          d="M32 0.470703H0V32.4707H32V0.470703Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_5536_12869)">
                        <path
                          d="M28.25 0.470703H3.75C1.67893 0.470703 0 2.14964 0 4.2207V28.7207C0 30.7918 1.67893 32.4707 3.75 32.4707H28.25C30.3211 32.4707 32 30.7918 32 28.7207V4.2207C32 2.14964 30.3211 0.470703 28.25 0.470703Z"
                          fill="white"
                        />
                        <path
                          d="M22.244 6.7207H25.552L18.325 14.9807L26.827 26.2207H20.17L14.956 19.4037L8.99 26.2207H5.68003L13.41 17.3857L5.25403 6.7207H12.08L16.793 12.9517L22.244 6.7207ZM21.083 24.2407H22.916L11.084 8.5967H9.117L21.083 24.2407Z"
                          fill="#222C49"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_5536_12869">
                        <rect
                          width="32"
                          height="32"
                          fill="white"
                          transform="translate(0 0.470703)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/paymentlabs/"
                  aria-label="LinkedIn"
                >
                  <svg
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.4556 0.470927H2.35197C2.0473 0.466696 1.74478 0.522522 1.46168 0.635219C1.17859 0.747915 0.920472 0.915274 0.702066 1.12774C0.48366 1.3402 0.309246 1.59361 0.188784 1.87348C0.0683229 2.15336 0.00417337 2.45422 0 2.75889V30.1825C0.00417337 30.4872 0.0683229 30.788 0.188784 31.0679C0.309246 31.3478 0.48366 31.6012 0.702066 31.8137C0.920472 32.0261 1.17859 32.1935 1.46168 32.3062C1.74478 32.4189 2.0473 32.4747 2.35197 32.4705H29.4556C29.7603 32.4747 30.0628 32.4189 30.3459 32.3062C30.629 32.1935 30.8871 32.0261 31.1055 31.8137C31.3239 31.6012 31.4983 31.3478 31.6188 31.0679C31.7392 30.788 31.8034 30.4872 31.8076 30.1825V2.75889C31.8034 2.45422 31.7392 2.15336 31.6188 1.87348C31.4983 1.59361 31.3239 1.3402 31.1055 1.12774C30.8871 0.915274 30.629 0.747915 30.3459 0.635219C30.0628 0.522522 29.7603 0.466696 29.4556 0.470927ZM9.64786 27.2546H4.84793V12.8548H9.64786V27.2546ZM7.2479 10.8388C6.58593 10.8388 5.95107 10.5758 5.48298 10.1077C5.0149 9.63965 4.75193 9.00479 4.75193 8.34282C4.75193 7.68085 5.0149 7.04599 5.48298 6.5779C5.95107 6.10982 6.58593 5.84685 7.2479 5.84685C7.59941 5.80699 7.95537 5.84182 8.29248 5.94906C8.6296 6.05631 8.94026 6.23355 9.20412 6.46918C9.46799 6.70481 9.6791 6.99352 9.82366 7.3164C9.96821 7.63928 10.0429 7.98906 10.0429 8.34282C10.0429 8.69658 9.96821 9.04635 9.82366 9.36923C9.6791 9.69211 9.46799 9.98082 9.20412 10.2165C8.94026 10.4521 8.6296 10.6293 8.29248 10.7366C7.95537 10.8438 7.59941 10.8786 7.2479 10.8388ZM26.9596 27.2546H22.1597V19.5267C22.1597 17.5907 21.4717 16.3267 19.7277 16.3267C19.188 16.3307 18.6624 16.5 18.2219 16.8118C17.7813 17.1236 17.4469 17.563 17.2638 18.0707C17.1386 18.4467 17.0843 18.8428 17.1038 19.2387V27.2386H12.3038C12.3038 27.2386 12.3038 14.1507 12.3038 12.8388H17.1038V14.8707C17.5398 14.1141 18.174 13.4908 18.938 13.0679C19.7021 12.645 20.567 12.4385 21.4397 12.4708C24.6397 12.4708 26.9596 14.5347 26.9596 18.9667V27.2546Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@PaymentLabs"
                  aria-label="YouTube"
                >
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.6018 13.1016L14.6026 14.5606L14.6036 19.3592L20.3716 16.242L17.2034 14.519L14.6018 13.1016Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.7061 0.470703H4.90882C2.65542 0.470703 0.807556 2.3185 0.807556 4.5721V28.3692C0.807556 30.6231 2.65542 32.4707 4.90882 32.4707H28.7061C30.9596 32.4707 32.8076 30.6231 32.8076 28.3693V4.5721C32.8076 2.3185 30.9596 0.470703 28.7061 0.470703ZM27.4742 15.9328V17.3099C27.4742 19.1135 27.2656 20.9156 27.2656 20.9156C27.2656 20.9156 27.0608 22.4498 26.4204 23.125C25.6086 24.0134 24.6994 24.0168 24.2839 24.0696C21.2932 24.293 16.8068 24.3017 16.8068 24.3017C16.8068 24.3017 11.2542 24.2475 9.54496 24.0772C9.07142 23.9849 8.00369 24.0134 7.18902 23.1259C6.55036 22.4512 6.34849 20.9156 6.34849 20.9156C6.34849 20.9156 6.14089 19.1135 6.14089 17.3099V15.6211C6.14089 13.8192 6.34862 12.0173 6.34862 12.0173C6.34862 12.0173 6.55382 10.482 7.19249 9.80604C8.00722 8.91917 8.91436 8.9299 9.33176 8.87984C12.3194 8.6547 16.8025 8.6707 16.8025 8.6707H16.8124C16.8124 8.6707 21.2946 8.6547 24.2854 8.87844C24.7008 8.9299 25.6116 8.92724 26.4234 9.8141C27.0637 10.4885 27.2656 12.0206 27.2656 12.0206C27.2656 12.0206 27.4742 13.8192 27.4742 15.6211V15.9328Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <nav className="flex flex-col grow self-stretch text-base leading-6 text-white whitespace-nowrap max-md:mt-10">
              <h3 className="text-lg font-bold">Solutions</h3>
              <a href="/solution/esports" className="mt-6">
                Esports
              </a>
              <a href="/solution/sports" className="mt-6">
                Sports
              </a>
              <a href="/solution/nil" className="mt-6">
                NIL
              </a>
              <a href="/solution/creator" className="mt-6">
                Creator
              </a>
            </nav>
          </div>
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <nav className="flex flex-col grow self-stretch pb-9 text-base leading-6 text-white whitespace-nowrap max-md:mt-10">
              <h3 className="text-lg font-bold">Information</h3>
              <a href="/blog" className="mt-6">
                Blog
              </a>
              <a href="/about" className="mt-6">
                About
              </a>
              <a href="/contact-us" className="mt-6">
                Contact
              </a>
            </nav>
          </div>
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <nav className="flex flex-col grow self-stretch text-base leading-6 text-white max-md:mt-10">
              <h3 className="text-lg font-bold">Legal</h3>
              <a href="/legal/terms-of-service" className="mt-6">
                Terms of Service
              </a>
              <a href="/legal/privacy-policy" className="mt-6">
                Privacy Policy
              </a>
              <a href="/legal/biometric-data-privacy-policy" className="mt-6">
                Biometric Data Privacy Policy
              </a>
              <a href="/legal/service-agreement" className="mt-6">
                Service Agreement
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div className="shrink-0 mt-10 max-w-full h-px bg-gray-400 border border-gray-400 border-solid w-[1170px]" />
      <p className="mt-10 text-base font-semibold leading-6 text-white max-md:max-w-full">
        © 2024 Payment Pro Logistics, LLC.
      </p>
      <p className="mt-4 mb-6 text-xs leading-5 text-white max-md:max-w-full">
        Payment Labs is a financial technology company, and banking services
        around the world are provided by our partners. In the US, banking
        services are provided by Evolve Bank & Trust, member FDIC.
      </p>
    </footer>
  );
}
